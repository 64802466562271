import React from "react"

import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Calendar = () => {
  const iframe =
    '<iframe src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=suhgb2uktrdajhl5jivho48d6o%40group.calendar.google.com&amp;color=%23875509&amp;ctz=America%2FLos_Angeles" style="border-width:0; display: block;margin: 0 auto;max-height: 100%; max-width: 100%;" width="800" height="600" frameborder="0" scrolling="no"></iframe>'
  const theFrame = { __html: iframe }
  return (
    <Layout>
      <SEO title="日历 Calendar" />
      <Typography variant="h4" gutterBottom>
        Events Calendar
      </Typography>
      <div dangerouslySetInnerHTML={theFrame} />
    </Layout>
  )
}

export default Calendar
